import { useState, useEffect, useRef } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import { MoonLoader } from 'react-spinners';

import CustomBootstrapDialog, { BootstrapModalTitle } from '../../BootstrapModal';

import styles from '../styles';

import { useRenameModalState, closeRenameModal, handleFileActions, isNewFolderNameValid } from 'hooks/useMyFilesHook';
import { renameFile } from 'utils/intelyStorageUtils';
import { getCurrentOrganizationId } from 'utils/loginUtils';

const RenameFileModal = () => {
    const { open, path, isDirectory } = useRenameModalState();
    const inputRef = useRef();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setError('');
            inputRef.current.value = '';
        }
    }, [open]);

    const handleClose = () => {
        closeRenameModal();
    };

    const handleRename = async () => {
        if (error) {
            return;
        }

        setIsLoading(true);
        const name = inputRef.current.value;

        if (!name?.length || name.includes('/') || name.includes(' ')) {
            setError('File name cannot be empty or contain special characters');
            setIsLoading(false);
            return;
        }

        if (!isNewFolderNameValid(name)) {
            setError('File name already exists');
            setIsLoading(false);
            return;
        }

        renameFile(getCurrentOrganizationId(), path, name)
            .then(() => {
                handleFileActions('rename', { path, isDirectory, newName: name });
                setIsLoading(false);
                closeRenameModal();
            })
            .catch(() => {
                setIsLoading(false);
                setError('An error occurred. Please try again later.');
            });
    };

    return (
        <CustomBootstrapDialog open={open} sx={styles.dialogRoot(500)}>
            <BootstrapModalTitle sx={styles.title} onClose={handleClose}>
                Rename
            </BootstrapModalTitle>
            <DialogContent>
                <Typography sx={styles.inputLabel(error)}>
                    Rename <span style={{ color: 'red' }}>*</span>{' '}
                </Typography>
                <TextField
                    inputRef={inputRef}
                    autoFocus
                    id="name"
                    type="text"
                    fullWidth
                    sx={styles.input}
                    onChange={() => {
                        if (error) setError('');
                    }}
                    placeholder="File Name"
                    error={Boolean(error)}
                />
                {Boolean(error) && <FormHelperText error={Boolean(error)}>{error}</FormHelperText>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRename} sx={styles.submit}>
                    {isLoading ? <MoonLoader size={15} color="white" /> : 'Rename'}
                </Button>
            </DialogActions>
        </CustomBootstrapDialog>
    );
};

export default RenameFileModal;
