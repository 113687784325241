import path from 'path-browserify';

import { setStoreState, useStoreValue, getStoreState, setStoreStates } from './useStoreStateHook';
import { getFilesInDirectory } from 'utils/intelyStorageUtils';
import { getCurrentOrganizationId } from 'utils/loginUtils';
import { getOrganizationUsersData } from './useOrganizationUsersHook';
import { getCurrentUserData } from 'utils/loginUtils';
import { showSnackBarErrorNotification } from 'utils/snackBarNotificationUtils';

const intelyStorageStoreNamespace = 'intelyStorage';

/**
 * @typedef intelyStorageFolder
 * @property {string} id
 * @property {string} name
 * @property {string} type
 * @property {intelyStorageFolder[]} children
 * @property {string} path
 */

/**
 * @typedef intelyStorageDefaultState
 * @property {Map<string, intelyStorageFolder[]>} files
 * @property {intelyStorageFolder} selectedFolder
 */

/**
 * @typedef selectedFolderFile
 * @property {string} id
 * @property {string} name
 * @property {string} path
 * @property {string} modifiedAt
 * @property {string} modifierFirstName
 * @property {string} modifierLastName
 * @property {string} fileModifiedBy
 * @property {boolean} isDirectory
 */

/**
 * @typedef intelyStorageSelectedFolder
 * @property {selectedFolderFile[]} files
 * @property {string} path
 */

/**
 * @type {intelyStorageDefaultState}
 */
export const intelyStorageDefaultStates = {
    files: new Map(),
    selectedFolder: {
        files: [],
        path: '',
    },
    newlyAddedFile: '',

    // Modal States
    newFolderModal: {
        open: false,
        path: '',
    },
    moveModal: {
        open: false,
        fromPath: '',
        isDirectory: false,
    },
    copyModal: {
        open: false,
        fromPath: '',
        fileData: {},
    },
    renameModal: {
        open: false,
        path: '',
        isDirectory: false,
    },
    deleteModal: {
        open: false,
        path: '',
        isDirectory: false,
    },
    fileUploadModal: {
        open: false,
    },
    showFilter: false,
    fileFilters: {
        modifiedBy: '',
        modifiedDate: null,
        applied: false,
        searchTerm: '',
    },
    filteredFiles: {
        files: [],
        filtered: false,
    },
    currentSort: {
        field: '',
        direction: '',
    },
    isDataTableLoading: false,
};

export const resetToDefaultState = () => {
    setStoreStates({
        files: new Map(),
        selectedFolder: {
            files: [],
            path: '',
        },
        newlyAddedFile: '',
        newFolderModal: {
            open: false,
            path: '',
        },
        moveModal: {
            open: false,
            fromPath: '',
            isDirectory: false,
        },
        copyModal: {
            open: false,
            fromPath: '',
            fileData: {},
        },
        renameModal: {
            open: false,
            path: '',
            isDirectory: false,
        },
        deleteModal: {
            open: false,
            path: '',
            isDirectory: false,
        },
        fileUploadModal: {
            open: false,
        },
        showFilter: false,
        fileFilters: {
            modifiedBy: '',
            modifiedDate: null,
            applied: false,
            searchTerm: '',
        },
        filteredFiles: {
            files: [],
            filtered: false,
        },
        isDataTableLoading: false,
    });
};

/**
 * Gets intely storage files state.
 * @returns {Map<string, intelyStorageFolder[]>}
 */
const getIntelyStorageFiles = () => getStoreState('files', intelyStorageStoreNamespace)(new Map());

/**
 * Gets intely storage files state using a given path.
 * @param {string} path
 * @returns {intelyStorageFolder[]}
 */
export const getIntelyStorageFilesByPath = (path) =>
    getIntelyStorageFiles()?.get(path) || {
        folders: [],
    };

/**
 * Gets intely storage files state and subscribes to changes.
 * @returns {Map<string, intelyStorageFolder[]>}
 */
export const useIntelyStorageFiles = () => useStoreValue('files', intelyStorageStoreNamespace)(new Map());

/**
 * Gets intely storage selected item state and subscribes to changes.
 * @returns {intelyStorageFolder}
 */
export const useIntelyStorageSelectedFolder = () => useStoreValue('selectedFolder', intelyStorageStoreNamespace)({});

/**
 * Append files to intely storage files.
 * @param {string} path
 */
export const appendFilesToIntelyStoragePath = (path, files) => {
    setStoreState('files', intelyStorageStoreNamespace)(new Map(getIntelyStorageFiles()?.set(path, files)));
};

/**
 * Add file to intely storage file path.
 * @param {string} path
 * @param {intelyStorageFolder} file
 */
export const addFileToIntelyStoragePath = (path, file) => {
    const folder = getIntelyStorageFilesByPath(path);
    folder.folders.push(file);
    appendFilesToIntelyStoragePath(path, {
        ...folder,
    });
};

/**
 * Initializes the root intely storage files state.
 */
export const initIntelyStorageStates = async () => {
    try {
        const response = await getFilesInDirectory(getCurrentOrganizationId(), '', {
            onlyDirectories: true,
            isHidden: false,
        });

        const rootDirectories = response.results.map((file) => ({
            id: file._id,
            name: file.basename,
            path: file.relativePath,
        }));

        appendFilesToIntelyStoragePath('/', {
            folders: rootDirectories,
            currentSetNumber: response.currentResultSetNumber,
            numberOfSets: response.numberOfResultSets,
        });
        setSelectedFolderAndGetFiles('/');
    } catch {
    } finally {
        setStoreState('isDataTableLoading', intelyStorageStoreNamespace)(false);
    }
};

/**
 * Adds files to intely storage files state using a given path.
 * @param {string} path
 * @param {number} [resultSet]
 */
export const fetchAndAddFoldersToIntelyStorage = async (path, resultSet = 1) => {
    try {
        const response = await getFilesInDirectory(
            getCurrentOrganizationId(),
            path,
            {
                onlyDirectories: true,
                isHidden: false,
            },
            { resultSetNumber: resultSet },
        );

        const newFiles = response.results.map((file) => ({
            id: file._id,
            name: file.basename,
            path: file.relativePath,
        }));

        appendFilesToIntelyStoragePath(path, {
            folders: newFiles,
            currentSetNumber: response.currentResultSetNumber,
            numberOfSets: response.numberOfResultSets,
        });
    } catch {}
};

/**
 * Fetches the current page's files.
 * @param {string} path
 * @param {number} resultSet
 * @param {{}} [resultSetOptions]
 * @param {{}} [queryOptions]
 */
export const fetchCurrentPageFiles = async (path, resultSet = 1, resultSetOptions = {}, queryOptions = {}) => {
    try {
        const response = await getFilesInDirectory(getCurrentOrganizationId(), path, queryOptions, {
            resultSetNumber: resultSet,
            ...resultSetOptions,
        });

        const users = getOrganizationUsersData();

        const files = response.results.map((file) => {
            const user = users.find((user) => user.userId === file.fileModifiedBy);
            return {
                id: file._id,
                name: file.basename,
                path: file.relativePath,
                modifiedAt: file.fileModifiedAt,
                modifierFirstName: user?.firstName || '',
                modifierLastName: user?.lastName || '',
                fileModifiedBy: file.fileModifiedBy,
                isDirectory: file.isDirectory,
            };
        });

        setStoreState(
            'selectedFolder',
            intelyStorageStoreNamespace,
        )({
            files,
            path,
            totalFiles: response.totalNumberOfResults,
            resultSet,
        });
    } catch {}
};

/**
 * Sets the selected folder and gets the files in the selected folder.
 * @param {string} path
 */
export const setSelectedFolderAndGetFiles = async (path, resultSet = 1) => {
    try {
        // Set loader timeout to prevent flickering.
        const setLoaderTimeout = setTimeout(() => {
            setStoreState('isDataTableLoading', intelyStorageStoreNamespace)(true);
        }, 1000);

        const users = getOrganizationUsersData();

        const directoryResponse = await getFilesInDirectory(
            getCurrentOrganizationId(),
            path,
            { onlyDirectories: true },
            { resultSetNumber: resultSet, sorts: { fileModifiedAt: -1 } },
        );

        const directories = directoryResponse.results.map((file) => ({
            id: file._id,
            name: file.basename,
            path: file.relativePath,
        }));

        appendFilesToIntelyStoragePath(path, {
            folders: directories,
            currentSetNumber: directoryResponse.currentResultSetNumber,
            numberOfSets: directoryResponse.numberOfResultSets,
        });

        // Get files in the selected folder.
        const filesResponse = await getFilesInDirectory(
            getCurrentOrganizationId(),
            path,
            {},
            {
                resultSetNumber: resultSet,
                sorts: { fileModifiedAt: -1 },
            },
        );

        const selectedFolderContents = filesResponse.results.map((file) => {
            const user = users.find((user) => user.userId === file.fileModifiedBy);
            return {
                id: file._id,
                name: file.basename,
                path: file.relativePath,
                modifiedAt: file.fileModifiedAt,
                modifierFirstName: user?.firstName || '',
                modifierLastName: user?.lastName || '',
                fileModifiedBy: file.fileModifiedBy,
                isDirectory: file.isDirectory,
            };
        });

        setStoreState(
            'selectedFolder',
            intelyStorageStoreNamespace,
        )({
            files: selectedFolderContents,
            path,
            totalFiles: filesResponse.totalNumberOfResults,
            resultSet: resultSet,
        });

        clearTimeout(setLoaderTimeout);
    } catch (e) {
        showSnackBarErrorNotification('An error occured while requesting files.');
        setStoreState(
            'selectedFolder',
            intelyStorageStoreNamespace,
        )({
            files: [],
            path,
            totalFiles: 0,
            resultSet: 1,
        });
    } finally {
        setStoreState('isDataTableLoading', intelyStorageStoreNamespace)(false);
    }
};

/**
 * Refreshes the selected folder.
 * @returns {Promise<void>}
 */
export const refreshSelectedFolder = async () => {
    const selectedFolder = getStoreState('selectedFolder', intelyStorageStoreNamespace)({}),
        path = selectedFolder.path;

    setSelectedFolderAndGetFiles(path);
};

/**
 * Get current selected folder path.
 * @returns {string}
 */
export const getCurrentSelectedFolderPath = () =>
    getStoreState('selectedFolder', intelyStorageStoreNamespace)({}).path || '';

/**
 * Sets the newly added file id.
 * @param {string} path
 */
export const setNewlyAddedFile = (path) => setStoreState('newlyAddedFile', intelyStorageStoreNamespace)(path);

/**
 * Gets the newly added file id and subscribes to changes.
 * @returns {string}
 */
export const useNewlyAddedFile = () => useStoreValue('newlyAddedFile', intelyStorageStoreNamespace)('');

/**
 * Checks if new folder name is valid.
 * @param {string} folderName
 * @returns {boolean}
 */
export const isNewFolderNameValid = (folderName) => {
    const selectedFolderPath = getCurrentSelectedFolderPath();
    const files = getIntelyStorageFilesByPath(selectedFolderPath);

    return !files.folders.find((file) => file.name === folderName);
};

/**
 * Returns the file metadata for the updated file.
 * @param {string} filePath
 * @param {object} changes
 * @returns {Promise<selectedFolderFile>}
 */
const getUpdatedFileMetaData = async (filePath, changes) => {
    const currentUser = await getCurrentUserData();
    const selectedFolder = getStoreState('selectedFolder', intelyStorageStoreNamespace)({}),
        fileToUpdate = selectedFolder.files.find((file) => file.path === filePath) || {};

    return {
        id: fileToUpdate.id || changes.id,
        name: changes.name || fileToUpdate.name,
        path: changes.path || fileToUpdate.path,
        modifiedAt: new Date().toISOString(),
        modifierFirstName: currentUser.firstName,
        modifierLastName: currentUser.lastName,
        fileModifiedBy: currentUser.userId,
        isDirectory: fileToUpdate.isDirectory || changes.isDirectory,
    };
};

/**
 * Handle file actions
 * @param {string} actionType - create-folder | create-file | move | copy | rename | delete
 * @param {object} fileData
 */
export const handleFileActions = async (actionType, fileData = {}) => {
    let shouldResetNewlyAddedFile = false;
    try {
        switch (actionType) {
            case 'create-folder': {
                const folderPath = fileData.name.includes('/') ? path.dirname(fileData.name) : '/';
                const folderName = path.basename(fileData.name);

                const newFile = {
                    // New file ID is not available from the API response.
                    id: folderName,
                    name: folderName,
                    path: fileData.name,
                };

                const folder = getIntelyStorageFilesByPath(folderPath);
                folder.folders.push(newFile);
                appendFilesToIntelyStoragePath(folderPath, {
                    ...folder,
                });

                const selectedFolder = getStoreState('selectedFolder', intelyStorageStoreNamespace)({});

                // Ignore selected folder update if the new item is not in the selected folder.
                if (selectedFolder.path === folderPath) {
                    setNewlyAddedFile(fileData.name);
                    shouldResetNewlyAddedFile = true;

                    const newFileForSelectedFolder = await getUpdatedFileMetaData('', {
                        id: folderName,
                        name: folderName,
                        path: fileData.name,
                        isDirectory: true,
                    });

                    const newFiles = [newFileForSelectedFolder, ...selectedFolder.files];

                    setStoreState(
                        'selectedFolder',
                        intelyStorageStoreNamespace,
                    )({
                        path: selectedFolder.path,
                        files: newFiles,
                        totalFiles: selectedFolder.totalFiles + 1,
                        resultSet: selectedFolder.resultSet,
                    });
                }
                break;
            }
            case 'create-file': {
                const selectedFolder = getStoreState('selectedFolder', intelyStorageStoreNamespace)({}),
                    newFileForSelectedFolder = await getUpdatedFileMetaData('', {
                        id: fileData.name,
                        name: fileData.name,
                        path: selectedFolder.path === '/' ? fileData.name : selectedFolder.path + '/' + fileData.name,
                        isDirectory: false,
                    }),
                    newFiles = [newFileForSelectedFolder, ...selectedFolder.files];

                setNewlyAddedFile(fileData.name);
                shouldResetNewlyAddedFile = true;

                setStoreState(
                    'selectedFolder',
                    intelyStorageStoreNamespace,
                )({
                    path: selectedFolder.path,
                    files: newFiles,
                    totalFiles: selectedFolder.totalFiles + 1,
                    resultSet: selectedFolder.resultSet,
                });

                break;
            }

            case 'move': {
                const selectedFolder = getStoreState('selectedFolder', intelyStorageStoreNamespace)({}),
                    selectedFolderFiles = selectedFolder.files || [],
                    fileMetaData = selectedFolderFiles.find((file) => file.path === fileData.path);

                if (!fileMetaData) {
                    return;
                }

                const oldPath = !fileData.path.includes('/') ? '/' : fileData.path.split('/').slice(0, -1).join('/');

                if (fileData.isDirectory) {
                    if (oldPath !== fileData.newPath) {
                        const newDirectoryData = {
                            id: fileMetaData.id,
                            name: fileMetaData.name,
                            path:
                                fileData.newPath === '/'
                                    ? fileMetaData.name
                                    : fileData.newPath + '/' + fileMetaData.name,
                        };

                        // ==> Remove old path item from old directory path
                        const oldDirectory = getIntelyStorageFilesByPath(oldPath).folders.filter(
                            (directory) => directory.path !== fileData.path,
                        );
                        // ==> And add it to the new directory path
                        const newDirectory = getIntelyStorageFilesByPath(fileData.newPath);
                        newDirectory.folders.unshift(newDirectoryData);

                        appendFilesToIntelyStoragePath(oldPath, {
                            ...oldDirectory,
                        });
                        appendFilesToIntelyStoragePath(fileData.newPath, {
                            ...newDirectory,
                        });
                    }
                }

                const updatedFileMetaData = await getUpdatedFileMetaData(fileMetaData.path, {
                    path: `${fileData.newPath}/${fileMetaData.name}`,
                    isDirectory: fileData.isDirectory,
                });

                const newFiles = selectedFolderFiles.filter((file) => file.path !== fileData.path);

                if (oldPath === fileData.newPath) {
                    newFiles.push(updatedFileMetaData);
                    setNewlyAddedFile(updatedFileMetaData.path);
                    shouldResetNewlyAddedFile = true;
                }

                setStoreState(
                    'selectedFolder',
                    intelyStorageStoreNamespace,
                )({
                    path: selectedFolder.path,
                    files: newFiles,
                    totalFiles: selectedFolder.totalFiles,
                    resultSet: selectedFolder.resultSet,
                });
                break;
            }
            case 'copy':
                if (fileData.isDirectory) {
                    const newDirectoryData = {
                        id: fileData.id,
                        name: fileData.name,
                        path: `${fileData.newPath}/${fileData.name}`,
                    };

                    const directoryData = getIntelyStorageFilesByPath(fileData.newPath);
                    directoryData.folders.unshift(newDirectoryData);
                    appendFilesToIntelyStoragePath(fileData.newPath, {
                        ...directoryData,
                    });
                }
                break;
            case 'rename': {
                const newPath = !fileData.path.includes('/')
                    ? fileData.newName
                    : fileData.path.split('/').slice(0, -1).join('/') + '/' + fileData.newName;

                if (fileData.isDirectory) {
                    const path = !fileData.path.includes('/') ? '/' : fileData.path.split('/').slice(0, -1).join('/');
                    const directory = getIntelyStorageFilesByPath(path);

                    appendFilesToIntelyStoragePath(path, {
                        ...directory,
                        folders: directory.folders.map((directory) => {
                            if (directory.path === fileData.path) {
                                return {
                                    ...directory,
                                    name: fileData.newName,
                                    path: newPath,
                                };
                            }
                            return directory;
                        }),
                    });
                }

                setNewlyAddedFile(newPath);

                const selectedFolder = getStoreState('selectedFolder', intelyStorageStoreNamespace)({}),
                    newFiles = selectedFolder.files.map((file) => {
                        if (file.path === fileData.path) {
                            return {
                                ...file,
                                name: fileData.newName,
                                path: newPath,
                            };
                        }
                        return file;
                    });

                setStoreState(
                    'selectedFolder',
                    intelyStorageStoreNamespace,
                )({
                    path: selectedFolder.path,
                    files: newFiles,
                    totalFiles: selectedFolder.totalFiles,
                    resultSet: selectedFolder.resultSet,
                });
                break;
            }
            case 'delete': {
                if (fileData.isDirectory) {
                    const path = !fileData.path.includes('/') ? '/' : fileData.path.split('/').slice(0, -1).join('/');
                    const directory = getIntelyStorageFilesByPath(path);
                    appendFilesToIntelyStoragePath(path, {
                        ...directory,
                        folders: directory.folders.filter((directory) => directory.path !== fileData.path),
                    });
                }

                const selectedFolder = getStoreState('selectedFolder', intelyStorageStoreNamespace)({}),
                    newFiles = selectedFolder.files.filter((file) => file.path !== fileData.path);
                setStoreState(
                    'selectedFolder',
                    intelyStorageStoreNamespace,
                )({
                    path: selectedFolder.path,
                    files: newFiles,
                    totalFiles: selectedFolder.totalFiles - 1,
                    resultSet: selectedFolder.resultSet,
                });
                break;
            }

            default:
                break;
        }
    } catch (error) {
        // TODO: Handle error
        // console.log(error);
    }

    if (shouldResetNewlyAddedFile) {
        setTimeout(() => {
            setNewlyAddedFile('');
        }, 4000);
    }
};

/**
 * Modal Actions
 */

/**
 * Open new folder modal state.
 * @param {string} path
 */
export const openNewFolderModal = (path) => {
    setStoreState(
        'newFolderModal',
        intelyStorageStoreNamespace,
    )({
        open: true,
        path,
    });
};

/**
 * Close new folder modal state.
 */
export const closeNewFolderModal = () => {
    setStoreState(
        'newFolderModal',
        intelyStorageStoreNamespace,
    )({
        open: false,
        path: '',
    });
};

/**
 * Get new folder modal state and subscribe to changes.
 * @returns {{open: boolean, path: string}}
 */
export const useNewFolderModalState = () =>
    useStoreValue('newFolderModal', intelyStorageStoreNamespace)({ open: false, path: '' });

/**
 * Open move modal state.
 * @param {string} fromPath
 */
export const openMoveModal = (fromPath, isDirectory) => {
    setStoreState(
        'moveModal',
        intelyStorageStoreNamespace,
    )({
        open: true,
        fromPath,
        isDirectory: Boolean(isDirectory),
    });
};

/**
 * Close move modal state.
 */
export const closeMoveModal = () => {
    setStoreState(
        'moveModal',
        intelyStorageStoreNamespace,
    )({
        open: false,
        fromPath: '',
        isDirectory: false,
    });
};

/**
 * Get move modal state and subscribe to changes.
 * @returns {{open: boolean, fromPath: string, isDirectory: boolean}}
 */
export const useMoveModalState = () =>
    useStoreValue('moveModal', intelyStorageStoreNamespace)({ open: false, fromPath: '', isDirectory: false });

/**
 * Open rename modal state.
 * @param {string} path
 */
export const openRenameModal = (path, isDirectory) => {
    setStoreState(
        'renameModal',
        intelyStorageStoreNamespace,
    )({
        open: true,
        path,
        isDirectory: Boolean(isDirectory),
    });
};

/**
 * Close rename modal state.
 */
export const closeRenameModal = () => {
    setStoreState(
        'renameModal',
        intelyStorageStoreNamespace,
    )({
        open: false,
        path: '',
        isDirectory: false,
    });
};

/**
 * Get rename modal state and subscribe to changes.
 * @returns {{open: boolean, path: string, isDirectory: boolean}}
 */
export const useRenameModalState = () =>
    useStoreValue('renameModal', intelyStorageStoreNamespace)({ open: false, path: '', isDirectory: false });

/**
 * Open copy modal.
 * @param {string} fromPath
 * @param {boolean} isDirectory
 */
export const openCopyModal = (fromPath, fileData = {}) => {
    setStoreState(
        'copyModal',
        intelyStorageStoreNamespace,
    )({
        open: true,
        fromPath,
        fileData: fileData,
    });
};

/**
 * Close copy modal.
 */
export const closeCopyModal = () => {
    setStoreState(
        'copyModal',
        intelyStorageStoreNamespace,
    )({
        open: false,
        fromPath: '',
        fileData: {},
    });
};

/**
 * Get copy modal state and subscribe to changes.
 * @returns {{open: boolean, fromPath: string, isDirectory: boolean}}
 */
export const useCopyModalState = () =>
    useStoreValue('copyModal', intelyStorageStoreNamespace)({ open: false, fromPath: '', fileData: {} });

/**
 * Open delete modal state.
 * @param {string} path
 */
export const openDeleteModal = (path, isDirectory) => {
    setStoreState(
        'deleteModal',
        intelyStorageStoreNamespace,
    )({
        open: true,
        path,
        isDirectory: Boolean(isDirectory),
    });
};

/**
 * Close delete modal state.
 */
export const closeDeleteModal = () => {
    setStoreState(
        'deleteModal',
        intelyStorageStoreNamespace,
    )({
        open: false,
        path: '',
    });
};

/**
 * Get delete modal state and subscribe to changes.
 * @returns {{open: boolean, path: string, isDirectory: boolean}}
 */
export const useDeleteModalState = () =>
    useStoreValue('deleteModal', intelyStorageStoreNamespace)({ open: false, path: '', isDirectory: false });

/**
 * Open file upload modal state.
 */
export const openFileUploadModal = () => {
    setStoreState(
        'fileUploadModal',
        intelyStorageStoreNamespace,
    )({
        open: true,
    });
};

/**
 * Close file upload modal state.
 */
export const closeFileUploadModal = () => {
    setStoreState(
        'fileUploadModal',
        intelyStorageStoreNamespace,
    )({
        open: false,
    });
};

/**
 * Get file upload modal state and subscribe to changes.
 * @returns {{open: boolean}}
 */
export const useFileUploadModalState = () =>
    useStoreValue('fileUploadModal', intelyStorageStoreNamespace)({ open: false });

/**
 * Toggle filter state.
 */
export const toggleFilter = () => {
    setStoreState(
        'showFilter',
        intelyStorageStoreNamespace,
    )(!getStoreState('showFilter', intelyStorageStoreNamespace)(false));
};

/**
 * Get filter state and subscribe to changes.
 * @returns {boolean}
 */
export const useFilterState = () => useStoreValue('showFilter', intelyStorageStoreNamespace)(false);

/**
 * Set filtered files.
 * @param {selectedFolderFile[]} files
 */
export const setFilteredFiles = (files) => {
    setStoreState(
        'filteredFiles',
        intelyStorageStoreNamespace,
    )({
        files,
        filtered: true,
    });
};

/**
 * Get filtered files and subscribe to changes.
 * @returns {selectedFolderFile[]}
 */
export const useFilteredFiles = () =>
    useStoreValue(
        'filteredFiles',
        intelyStorageStoreNamespace,
    )({
        files: [],
        filtered: false,
    });

/**
 * Reset filtered files.
 */
export const resetFilteredFiles = () => {
    setStoreState(
        'filteredFiles',
        intelyStorageStoreNamespace,
    )({
        files: [],
        filtered: false,
    });
};

/**
 * Set file filters state.
 * @param {string} state
 * @param {string} value
 */
export const setFileFilters = (state, value) => {
    setStoreState(
        'fileFilters',
        intelyStorageStoreNamespace,
    )({
        ...getStoreState('fileFilters', intelyStorageStoreNamespace)({}),
        [state]: value,
    });
};

/**
 * Get file filters state and subscribe to changes.
 * @returns {{modifiedBy: string, modifiedDate: string|null}}
 */
export const useFileFilters = () =>
    useStoreValue(
        'fileFilters',
        intelyStorageStoreNamespace,
    )({ modifiedBy: '', modifiedDate: null, applied: false, searchTerm: '' });

/**
 * Apply file filters.
 */
export const applyFileFilters = () => {
    const fileFilters = getStoreState('fileFilters', intelyStorageStoreNamespace)({}),
        selectedFolder = getStoreState('selectedFolder', intelyStorageStoreNamespace)({}),
        files = selectedFolder.files;
    let filteredFiles = files,
        hasFilters = false;

    // Searched files need to be re-filtered to apply filters.
    if (fileFilters.searchTerm.length) {
        filteredFiles = filteredFiles.filter((file) => file.name.toLowerCase().includes(fileFilters.searchTerm));
        hasFilters = true;
    }

    if (fileFilters.modifiedBy) {
        filteredFiles = filteredFiles.filter((file) => file.fileModifiedBy === fileFilters.modifiedBy);
        hasFilters = true;
    }

    if (fileFilters.modifiedDate) {
        filteredFiles = filteredFiles.filter(
            (file) => new Date(file.modifiedAt).getTime() >= new Date(fileFilters.modifiedDate).getTime(),
        );
        hasFilters = true;
    }

    if (hasFilters) {
        setFilteredFiles(filteredFiles);
        setFileFilters('applied', true);
    }
};

// /**
//  * Filter files by search term.
//  * @param {string} searchTerm
//  */
export const filterFilesBySearchTerm = () => {
    const sort = getStoreState('currentSort', intelyStorageStoreNamespace)({}),
        fileFilters = getStoreState('fileFilters', intelyStorageStoreNamespace)({}),
        searchTerm = fileFilters.searchTerm;

    const sortOptions = {};

    if (sort.field.length) {
        sortOptions[sort.field] = sort.direction;
    }

    fetchCurrentPageFiles(getCurrentSelectedFolderPath(), 1, { sorts: sortOptions }, { fileNameMatch: searchTerm });
};

/**
 * Reset file filters.
 * @param {boolean} [reapplySearchTerm = false]
 */
export const resetFileFilters = (reapplySearchTerm = false) => {
    const fileFilters = getStoreState('fileFilters', intelyStorageStoreNamespace)({});

    // Reset filtered files to result from search.
    if (reapplySearchTerm && fileFilters.searchTerm.length) {
        filterFilesBySearchTerm();
    } else {
        resetFilteredFiles();
    }

    setStoreState(
        'fileFilters',
        intelyStorageStoreNamespace,
    )({
        modifiedBy: '',
        modifiedDate: null,
        applied: false,
        searchTerm: fileFilters.searchTerm.length ? fileFilters.searchTerm : '',
    });
};

/**
 * Set data table loading state.
 * @param {boolean} isLoading
 */
export const setDataTableLoading = (isLoading) => {
    setStoreState('isDataTableLoading', intelyStorageStoreNamespace)(isLoading);
};

/**
 * Get data table loading state and subscribe to changes.
 * @returns {boolean}
 */
export const useDataTableLoading = () => useStoreValue('isDataTableLoading', intelyStorageStoreNamespace)(false);

/**
 * Set current sort state.
 * @param {string} field
 * @param {string} direction
 */
export const setCurrentSort = (field, direction) => {
    setStoreState(
        'currentSort',
        intelyStorageStoreNamespace,
    )({
        field,
        direction,
    });
};

/**
 * Get current sort state and subscribe to changes.
 * @returns {{field: string, direction: string}}
 */
export const useCurrentSort = () =>
    useStoreValue(
        'currentSort',
        intelyStorageStoreNamespace,
    )({
        field: '',
        direction: '',
    });
