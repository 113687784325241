import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { MoonLoader } from 'react-spinners';

import CustomBootstrapDialog, { BootstrapModalTitle } from '../../BootstrapModal';

import styles from '../styles';

import { useDeleteModalState, closeDeleteModal, handleFileActions } from 'hooks/useMyFilesHook';
import { deleteFile } from 'utils/intelyStorageUtils';
import { getCurrentOrganizationId } from 'utils/loginUtils';

const DeleteFileModal = () => {
    const { open, path, isDirectory } = useDeleteModalState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setIsLoading(false);
        }
    }, [open]);

    const handleClose = () => {
        closeDeleteModal();
        setIsLoading(false);
    };

    const handleDelete = async () => {
        setIsLoading(true);
        deleteFile(getCurrentOrganizationId(), path)
            .then(() => {
                handleFileActions('delete', { path, isDirectory });
                setIsLoading(false);
                closeDeleteModal();
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const warningText = isDirectory
        ? "Are you sure you want to delete this folder and all of it's contents?"
        : 'Are you sure you want to delete this file?';

    return (
        <CustomBootstrapDialog open={open} sx={styles.dialogRoot(500)}>
            <BootstrapModalTitle sx={styles.title} onClose={handleClose}>
                Delete File
            </BootstrapModalTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography style={styles.modalContent}>{warningText}</Typography>
                <DialogActions>
                    <Button onClick={handleDelete} sx={styles.submit}>
                        {isLoading ? <MoonLoader size={15} color="white" /> : 'Yes'}
                    </Button>
                    <Button onClick={handleClose} sx={styles.cancel}>
                        No
                    </Button>
                </DialogActions>
            </DialogContent>
        </CustomBootstrapDialog>
    );
};

export default DeleteFileModal;
