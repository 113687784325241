import { authorizedAxiosInstance, unauthorizedAxiosInstance } from '../axiosInstances';
import { Cookies } from 'react-cookie';
import { createMemoryHistory } from 'history';

import store from '../redux/store';
import * as constants from '../config/constants';
import HttpStatusCodes from '../classes/HttpStatusCodes';
import { setStoreState } from '../hooks/useStoreStateHook';

import {
    getLocalStorageCacheItem,
    setLocalStorageCacheItem,
    destroyLocalStorageCache,
    removeLocalStorageCacheItem,
} from '../hooks/useLocalStorageHook';

import {
    showSnackBarErrorNotification,
    showSnackBarSuccessNotification,
    showSnackBarSessionIdleTimeNotification,
} from './snackBarNotificationUtils';
import { getGatewayUrl, logError } from './envUtils';

const cookies = new Cookies();
let sessionCheckInterval;

export const AUTH_USER_SESSION_TOKEN_ACTIONS = {
    sessionPopupLastDisplayTime: 'sessionPopupLastDisplayTime',
};

const sessionPopupLastDisplayTimeActionType = AUTH_USER_SESSION_TOKEN_ACTIONS.sessionPopupLastDisplayTime;

/**
 * Process the request response object
 * @param {Object} response
 * @param {Function|null} callback Success callback
 * @returns {any}
 */
const processRequestResponse = (response, callback = null) => {
    if (response?.status === HttpStatusCodes.SUCCESS) {
        if (callback) {
            callback(response.data);
        }

        return response.data;
    } else {
        throw response.errors;
    }
};

/**
 * Validate the authenticated user session token
 * @returns {Promise<AxiosResponse|boolean>}
 */
export const getUserLogin = async () => {
    if (hasUserLoggedIn()) {
        return authorizedAxiosInstance
            .get(getGatewayUrl(`/currentUser/validate`))
            .then(processRequestResponse)
            .catch((error) => {
                logError(error);
                return false;
            });
    }

    return false;
};

/**
 * Get user data from back-end.
 * @param {String} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUserData = async (userId) => {
    return unauthorizedAxiosInstance
        .get(getGatewayUrl(`/user/${userId}`))
        .then(processRequestResponse)
        .catch((error) => {
            logError(error);
            return false;
        });
};

export const redirectUserTokenExpired = () => {
    logout('session.expired');
};

/**
 * Get the authenticated user session token.
 * @returns {String}
 */
export const getCurrentUserToken = () => {
    return localStorage.getItem('token');
};

/**
 *
 * @returns {boolean}
 */
export const hasUserLoggedIn = () => {
    return cookies.get('hasLoggedIn') === 'true';
};

/**
 * Check whether the current user session
 *
 * Note: Token refresh is triggered when the auth user session
 * expiration time left is just 1 minute.
 *
 * @returns {Promise<boolean>}
 */
export const isCurrentUserLoginValid = async () => {
    const userData = await getCurrentUserTokenData();

    // Monitor the session token expiration and alert the user if necessary
    handleSessionTokenRefreshUsingPopup(userData);

    return userData && userData.id;
};

/**
 * Get the authenticated user data.
 * @returns {Promise<AxiosResponse<*> | boolean>}
 */
export const getCurrentUserTokenData = async () => {
    return getUserLogin()
        .then(async (data) => {
            return {
                ...data,
                id: data.userId, // TODO this should be removed to use userId
            };
        })
        .catch(() => {
            return false;
        });
};

/**
 * Get current authenticated user organization ID
 * @param {Object} userData
 * @returns {String}
 */
export const getUserCurrentOrganizationId = (userData) =>
    Array.isArray(userData?.organizations) && userData.organizations?.length ? userData.organizations[0] : '';

/**
 * Get current authenticated user organization ID
 * @param {String} userId
 * @param {String} currentOrganizationId
 * @returns {Promise<void>}
 */
export const getUserCurrentOrganizationData = async (userId, currentOrganizationId) => {
    let organizationData = { stripeCustomerId: '' },
        organizationId;

    if (getCurrentOrganizationId()) {
        organizationId = getCurrentOrganizationId();
    } else {
        organizationId = currentOrganizationId;
        localStorage.setItem('currentOrganizationId', organizationId);
    }

    try {
        organizationData = await unauthorizedAxiosInstance
            .get(getGatewayUrl(`/user/${userId}/organization/${organizationId}`))
            .then(processRequestResponse)
            .catch((error) => {
                throw error;
            });
    } catch (error) {
        // TODO log exception
    }

    return organizationData;
};

/**
 * Update the user store data with the current authenticated user
 * @param {Object} data
 * @returns {Promise<void>}
 */
export const updateCurrentUserData = async (data) => {
    await setupAuthUserDataWithStore(store, data);
};

/**
 * Setup the authenticated user data to the redux store.
 * @param {Object} reduxStore Redux store
 * @param {Object} data User data
 * @returns {Promise<void>}
 */
export const setupAuthUserDataWithStore = async (reduxStore, data) => {
    try {
        const currentOrganizationId = getUserCurrentOrganizationId(data),
            organizationData = await getUserCurrentOrganizationData(data.userId, data.defaultOrganizationId);

        const userData = {
            ...data,
            stripeId: organizationData?.stripeId,
            has_payment: false,
            organizations: currentOrganizationId,
            sessionInitiatedAt: data.iat,
            sessionExpiredAt: data.exp,
            defaultOrganizationId: data.defaultOrganizationId,
            refreshedAt: Date.now(),
        };

        delete userData.iat;
        delete userData.exp;

        setCurrentUserStoreData(reduxStore, userData);
    } catch (e) {
        // TODO: handle error
    }
};

/**
 * Set the user data in redux store
 * @param {object} reduxStore Redux store instance
 * @param {object} userData User data
 */
const setCurrentUserStoreData = (reduxStore, userData = {}) => {
    reduxStore.dispatch({
        type: 'SET_CURRENT_USER_DATA',
        payload: userData,
    });
};

/**
 * Set up a session timeout validation interval which will monitor
 * for a valid user session.
 *
 * @returns {void}
 */
export const setupSessionTimeoutValidation = () => {
    if (sessionCheckInterval) {
        clearInterval(sessionCheckInterval);
    }

    sessionCheckInterval = setInterval(validateSessionTimeout, constants.SESSION_TIMEOUT_CHECK_INTERVAL);
};

/**
 * Validate user session. If the session is invalid/expired,
 * the session is wiped out and user is redirected to platform
 * login page.
 *
 * @returns {Promise<boolean>}
 */
export const validateSessionTimeout = async () => {
    if (await isCurrentUserLoginValid()) {
        return true;
    } else {
        clearInterval(sessionCheckInterval);
        redirectUserTokenExpired();
        return false;
    }
};

/**
 * Wipe out the user session and go to the platform login page.
 *
 * @param {String} reason Reason why the user is logged out.
 * Accepted values:
 * - 'session.expired': User session expired
 * - 'session.invalid': User session invalid
 * - 'session.destroy': User logged out by using the log out button/link
 * - 'session.application.error': Application error occurred during login
 * process
 * @param {Boolean} redirectToAuthPage Whether to redirect to to auth page
 * after destroying auth user token. Default: true.
 */
export const logout = (reason = '', redirectToAuthPage = true, isForbiddenError = false) => {
    return unauthorizedAxiosInstance
        .post(getGatewayUrl(`/noauth/user/logout`))
        .then((response) => {
            return processRequestResponse(response, async () => {
                handleLogoutReason(reason);
                destroyLocalStorageCache(maybePreserveLocalStatesByReason(reason));

                // If redirect to auth page, ensure infinite redirect is prevented.
                if (window && redirectToAuthPage && !isRootPage()) {
                    if (isForbiddenError) {
                        window.location.replace('/?forbidden=true');
                    } else {
                        window.location.replace('/');
                    }
                }
            });
        })
        .catch((error) => {
            logError(error, error.toString());
        });
};

/**
 * Check whether we are on the root page ('/') or not.
 * @returns {Boolean} True if root page is active. Otherwise false.
 */
export const isRootPage = () => '/' === window.location.pathname;

/**
 * Refresh the authenticated user session token
 *
 * @param {String} userId
 * @param {String} token user session token
 * @param {Object} notification Specify whether to show a notification to the user based on the response.
 * @param {Boolean} [notification.error] Whether to show error notification if request failed
 * @param {Boolean} [notification.success] Whether to show success notification if request was successful.
 *
 * @return {Promise<object|void>}
 */
export const refreshUserToken = async (
    userId,
    notification = {
        error: true,
        success: true,
    },
) => {
    return authorizedAxiosInstance
        .post(getGatewayUrl(`/user/${userId}/refreshToken`))
        .then((response) => {
            return processRequestResponse(response, async () => {
                const responseUserData = response?.data?.tokenPayload;

                await updateCurrentUserData(responseUserData); // TODO more efficient way?
                updateUserLastRefreshTime();
                updateUserLastActivityTime();
                setSessionPopupLastDisplayTime(0);

                if (notification?.success) {
                    showSnackBarSuccessNotification('Your session has been refreshed successfully.');
                }
            });
        })
        .catch(() => {
            if (notification?.error) {
                showSnackBarErrorNotification('An error occurred, unable to refresh your session token.');
            }
        });
};

/**
 * Display a popup to the user which allows them to refresh their current session which will time out due to inactivity.
 * @param {Object} userData
 * @see isCurrentUserLoginValid()
 */
export const handleSessionTokenRefreshUsingPopup = (userData) => {
    const hasUserData = userData && userData.id,
        tokenExpiration = parseInt(userData?.exp) * 1000;

    if (hasUserData && !isNaN(tokenExpiration)) {
        const timeUntilTokenExpiration = tokenExpiration - Date.now(),
            sessionPopupLastDisplayTime = getSessionPopupLastDisplayTime();

        // Purposefully left these useful debug statements in for future diagnosis
        // console.log('background: token expiration:', tokenExpiration);
        // console.log('background: token expires in minutes:', timeUntilTokenExpiration / 60000);
        // console.log('background: idle popup shown:', isSessionIdlePopupShown());
        // console.log('background: sessionPopupLastDisplayTime', sessionPopupLastDisplayTime);

        if (
            timeUntilTokenExpiration > 0 &&
            timeUntilTokenExpiration <= constants.SESSION_TOKEN_REFRESH_DISPLAY_POPUP_THRESHOLD &&
            !isSessionIdlePopupShown()
        ) {
            // Show session popup in the next 2 minutes after
            // the last display if user mistakenly closes the snackbar popup

            if (sessionPopupLastDisplayTime === 0 || (Date.now() - sessionPopupLastDisplayTime) / 1000 >= 120) {
                showSnackBarSessionIdleTimeNotification();
            }
        }
    }
};

/**
 * Process the logout reason. This is used provide context to
 * which the user is logged out.
 * Especially useful for doing a redirect to url.
 *
 * @param {String} reason Reason why the user is logged out.
 * Accepted values:
 * - 'session.expired': User session expired
 * - 'session.invalid': User session invalid
 * - 'session.destroy': User logged out by using the log out button/link
 * - 'session.application.error': Application error occurred during login process
 */
const handleLogoutReason = (reason) => {
    if (!reason) {
        return;
    }

    if (reason === 'session.expired') {
        const currentPageUrl = getCurrentPagePath();

        setLocalStorageCacheItem(
            'session.states',
            {
                reason,
                redirectTo: currentPageUrl !== '/' ? currentPageUrl : '',
            },
            true,
        );
    }
};

/**
 * Get the current page path.
 * @returns {String}
 */
export const getCurrentPagePath = () => window.location.pathname + window.location.search + window.location.hash;

/**
 * Check whether we can restore user session states after log in.
 * @see handleLogoutReason()
 */
export const restoreUserSessionStates = () => {
    const sessionStates = getLocalStorageCacheItem('session.states');
    if (!sessionStates) {
        return;
    }

    const { redirectTo = '' } = sessionStates;

    if (redirectTo && redirectTo !== '/') {
        removeLocalStorageCacheItem('session.states');

        const history = createMemoryHistory();
        history.push(redirectTo);
    }
};

/**
 * Check whether we can preserve local states based on the reason
 * the user is logged out.
 *
 * @param {String} reason Reason why the user is logged out.
 * Accepted values:
 * - 'session.expired': User session expired
 * - 'session.invalid': User session invalid
 * - 'session.destroy': User logged out by using the log out button/link
 * - 'session.application.error': Application error occurred during login
 * process
 *
 * @returns {Array<string>} An array of reasons that specifies local states to
 * be preserved and restored once the user is logged-in back into the
 * application.
 */
const maybePreserveLocalStatesByReason = (reason) => {
    const preservableLocalStates = [];

    if (reason === 'session.states') {
        preservableLocalStates.push(reason);
    }

    return preservableLocalStates;
};

/**
 * Check whether the session idle time popup is shown
 * @returns {Boolean}
 */
export const isSessionIdlePopupShown = () => store.getState().feedback?.type === 'sessionIdleTimeCheck';

/**
 * Get the last activity time of the user
 * @returns {number} Timestamp of when the last user activity occurred
 */
export const getUserLastActivityTime = () => getLocalStorageCacheItem('lastActivityTime', 0);

/**
 * Update the last activity time of the user
 */
export const updateUserLastActivityTime = () => {
    setLocalStorageCacheItem('lastActivityTime', Date.now());
};

/**
 *
 * @returns {Number}
 */
export const getUserLastRefreshTime = () => getLocalStorageCacheItem('userLastRefreshTime', 0);

/**
 * Update the last time user data was refreshed from token payload.
 */
export const updateUserLastRefreshTime = () => {
    setLocalStorageCacheItem('userLastRefreshTime', Date.now());
};

/**
 * Set the session popup last display time.
 * @param {Number} timestamp
 */
export const setSessionPopupLastDisplayTime = (timestamp) => {
    store.dispatch({
        type: sessionPopupLastDisplayTimeActionType,
        payload: timestamp,
    });
};

/**
 * Get the session popup last display time.
 * @returns {Number}
 */
export const getSessionPopupLastDisplayTime = () =>
    store.getState().authUserSessionToken?.[sessionPopupLastDisplayTimeActionType];

/**
 * TODO implement based on banner data
 * @returns {boolean}
 */
export const getCurrentOrganizationId = () => {
    return localStorage.getItem('currentOrganizationId') || '';
};

/**
 *
 * @param {String} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getUserOrganizationList = async (userId) => {
    return authorizedAxiosInstance
        .get(getGatewayUrl(`/user/${userId}/organizations`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 * Get the authenticated user data.
 * @returns {Promise<AxiosResponse<*> | boolean>}
 */
export const getCurrentUserData = async () => {
    return getUserLogin()
        .then(async (data) => {
            // Fix the user id parameter
            const userId = data.userId;
            if (userId) {
                delete data.userId;
            }

            return {
                ...data,
                id: userId,
            };
        })
        .catch(() => {
            return false;
        });
};

/**
 * Set the auth email. Used in user registration/verification auth.
 * @param {string} email Auth email
 */
export const setAuthFlowEmail = (email) => {
    setStoreState('authEmail')(email);
};
