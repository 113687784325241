import { useState, useEffect } from 'react';
import path from 'path-browserify';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { MoonLoader } from 'react-spinners';

import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';

import CustomBootstrapDialog, { BootstrapModalTitle } from '../../BootstrapModal';
import CustomBreadCrumbs from 'components/Breadcrumbs/CustomBreadcrumbs';
import FileTree from '../FileTree';

import styles from '../styles';

import { useCopyModalState, closeCopyModal, handleFileActions, isNewFolderNameValid } from 'hooks/useMyFilesHook';
import { getCurrentOrganizationId } from 'utils/loginUtils';
import { copyFile, addNewFolder } from 'utils/intelyStorageUtils';

const CopyFileModal = () => {
    const { open, fromPath, fileData } = useCopyModalState();
    const [newPath, setNewPath] = useState('/');
    const [newFolder, setNewFolder] = useState({
        name: '',
        open: false,
        error: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setIsLoading(false);
            setNewPath('/');
            setNewFolder({
                name: '',
                open: false,
                error: '',
            });
        }
    }, [open]);

    const handleCopyFile = () => {
        setIsLoading(true);
        copyFile(
            getCurrentOrganizationId(),
            fromPath,
            newPath === '/' ? path.basename(fromPath) : `${newPath}/${path.basename(fromPath)}`,
        )
            .then(() => {
                handleFileActions('copy', {
                    newPath,
                    isDirectory: fileData.isDirectory,
                    id: fileData.id,
                    name: fileData.name,
                });
                setIsLoading(false);
                handleClose();
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const handleClose = () => {
        closeCopyModal();
    };

    const handleNewFolderToggle = () => {
        setNewFolder((prevState) => ({
            name: '',
            open: !prevState.open,
            error: '',
        }));
    };

    const handleFolderNameChange = (name) => {
        setNewFolder((prevState) => ({
            ...prevState,
            error: '',
            name,
        }));
    };

    const handlePathChange = (path) => {
        setNewPath(path);
        setNewFolder({
            name: '',
            open: false,
            error: '',
        });
    };

    const handleAddNewFolder = async () => {
        if (newFolder.error) {
            return;
        }

        setIsLoading(true);

        let error = '';
        const folderName = newFolder.name;

        if (!folderName?.length || folderName.includes('/')) {
            error = 'Folder name cannot be empty or contain special characters';
        } else if (!isNewFolderNameValid(folderName)) {
            error = 'Folder name already exists';
        }

        if (error.length) {
            setNewFolder((prevState) => ({
                ...prevState,
                error,
            }));
            setIsLoading(false);
            return;
        }

        try {
            const pathForNewFolder = newPath === '/' ? '' : newPath;
            const response = await addNewFolder(
                getCurrentOrganizationId(),
                pathForNewFolder[0] === '/' ? pathForNewFolder.slice(1) : pathForNewFolder,
                folderName,
            );
            handleFileActions('create-folder', { name: response.relativePath });
            setNewFolder({
                name: '',
                open: false,
                error: '',
            });
            setNewPath(response.relativePath);
        } catch (error) {
            setNewFolder((prevState) => ({
                ...prevState,
                error: error.response?.data?.error || 'An error occurred while creating the folder',
            }));
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setNewFolder({
            name: '',
            open: false,
            error: '',
        });
    };

    const breadcrumbItems = newPath === '/' ? ['All Folders', ''] : ['All Folders', ...newPath.split('/'), ''];
    const handleBreadcrumbClick = (_, index) => {
        if (index === 0) {
            setNewPath('/');
        } else {
            setNewPath(newPath.split('/').slice(0, index).join('/'));
        }
    };

    return (
        <CustomBootstrapDialog open={open} sx={styles.dialogRoot(700, 600)}>
            <BootstrapModalTitle sx={styles.title} onClose={handleClose}>
                Copy To
            </BootstrapModalTitle>
            <DialogContent>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '6px',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <CustomBreadCrumbs items={breadcrumbItems} separator="/" onClick={handleBreadcrumbClick} />
                    <Button onClick={handleNewFolderToggle} sx={styles.newFolderButton}>
                        <CreateNewFolderOutlinedIcon color="#3A70D4" />
                    </Button>
                </div>
                <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />
                <div style={{ height: '400px', paddingBottom: '16px', overflowY: 'auto' }}>
                    <FileTree
                        selectedPath={newPath}
                        onFolderClick={handlePathChange}
                        newFolder={newFolder}
                        onNewFolderNameChange={handleFolderNameChange}
                        addNewFolder={handleAddNewFolder}
                        cancel={handleCancel}
                    />
                </div>
            </DialogContent>
            <DialogActions
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%',
                    columnGap: '12px',
                }}
            >
                <Button onClick={handleCopyFile} sx={styles.submit}>
                    {isLoading ? <MoonLoader size={20} color="#fff" /> : 'Copy Here'}
                </Button>
                <Button onClick={handleClose} sx={styles.cancel}>
                    Cancel
                </Button>
            </DialogActions>
        </CustomBootstrapDialog>
    );
};

export default CopyFileModal;
